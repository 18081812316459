<template>
  <div class="settings">
    <ValidationObserver v-slot="{ pristine, valid }" ref="validationObserver">
      <ContentBlock class="form" :required="true">
        <div slot="instructions">
          <h5>{{ $t("Contact Information") }}</h5>
          <p>{{ $t("Enter your contact information and preferences here. Even if you turn all email notifications off, you may still receive some emails pertaining to legal changes and other important announcements.") }}</p>
        </div>
        <div slot="content">
          <div class="userinfos">
            <UIInput maxlength="255" class="input" :title="$t('Name')" v-model="user.name" required/>
            <UIInput
              maxlength="255"
              class="input"
              :title="$t('Email')"
              v-model="user.email"
              required
              rules="required|email"
            />
            <UISwitch
              :title="$t('Receive Reservation Emails')"
              :instructions="$t('Such as reservation confirmations and cancellations.')"
              v-model="user.reservationEmails"
            />
            <UISwitch
              :title="$t('Receive Product Emails')"
              :instructions="$t('Such as content and feature updates.')"
              v-model="user.systemEmails"
            />
          </div>
        </div>
      </ContentBlock>
      <PasswordChange :ignoreValidationForParent="true" />
      <BottomBar @save="saveUser" @cancel="$router.go(-1)" :saving="saving" :valid="!pristine && valid"/>
    </ValidationObserver>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';

import FocusModeMixin from '../misc/FocusModeMixin';
import ContentBlock from '../components/ContentBlock';
import BottomBar from '../components/BottomBar';
import UIInput from '../components/ui/UIInput';
import UISwitch from '../components/ui/UISwitch';
import PasswordChange from './PasswordChange';

import { isEqual } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    UIInput,
    UISwitch,
    PasswordChange,
    BottomBar,
    ValidationObserver,
  },
})
export default class UserSettings extends mixins(FocusModeMixin) {
  userChanges = {};
  saving = false;

  get userInStore () {
    return this.$store.getters.currentUser;
  }

  get user () {
    return new Proxy({ ...this.userInStore, ...this.userChanges }, {
      set: (target, property, value) => {
        this.editUser(property, value);
        return true;
      },
    });
  }

  editUser (property, value) {
    const dirty = !isEqual(this.userInStore[property], value);
    if (dirty) this.$set(this.userChanges, property, value);
    else this.$delete(this.userChanges, property);
  }

  get savedMsg () {
    return `${this.user.name} ${this.$t('Saved')}`; // WORKAROUND see https://github.com/kazupon/vue-i18n/issues/184
  }

  async created () {
    this.$store.commit('setTitle', this.$t('My Profile'));
  }

  async saveUser () {
    this.saving = true;
    try {
      const { isValid } = await this.$refs.validationObserver.validateWithInfo();
      if (!isValid) return;

      await this.$store.dispatch('saveUser', { id: this.user.id, ...this.userChanges });
      this.$store.commit('setFlash', {
        type: 'success',
        message: this.savedMsg,
      });
      this.$router.go(-1);
    } finally {
      this.saving = false;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "../styles";

.settings {
  @apply --container;

  margin-top: var(--spacingLg);

  & .userinfos {
    display: grid;
    grid-gap: 4rem var(--spacingSm);
    grid-template-columns: 1fr;

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
  & .form {
    & .input-group {
      @apply --inputGroup;

      margin-bottom: var(--spacingSm);
      &:last-child {
        margin-bottom: 0;
      }
    }

    & input {
      @apply --text;
    }
  }

  & .address,
  & .region,
  & .contact {
    padding-top: var(--spacingSm);

    @media (--tablet) {
      display: flex;

      & > * {
        flex: 1 1 auto;
        margin-right: var(--spacingSm);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
