<template>
  <ValidationObserver ref="validationObserver" v-slot="{ pristine, invalid }" :disabled="ignoreValidationForParent">
    <content-block class="password-change" required>
      <div slot="instructions">
        <h5>{{ $t("Password Change") }}</h5>
        <p>{{ $t("A minimum of 6 characters is needed for passwords") }}</p>
      </div>

      <div slot="content">
        <div class="form">
          <UIInput
            type="password"
            class="currentpassword"
            :title="$t('Current Password')"
            v-model="currentPassword"
            rules="required|min:6"
            vid="currentPassword"
          />
          <UIInput
            type="password"
            class="newpassword"
            :title="$t('New Password')"
            v-model="password"
            rules="required|min:6|requireNumber|requireSpecial"
            vid="newPassword"
          />
          <UIInput
            type="password"
            class="confirmationpassword"
            :title="$t('Confirm New Password')"
            v-model="passwordConfirmation"
            rules="required|confirmed:@newPassword"
            vid="confirmPassword"
          />
          <UIButton
            class="submitbtn"
            v-if="!success"
            :disabled="pristine || invalid"
            @click="changePassword"
          >{{ $t('Change Password') }}</UIButton>
          <div class="success" v-if="success">
            <svg>
              <use xlink:href="#icon-check"></use>
            </svg>
            {{ $t('Successfully Changed Password') }}
          </div>
        </div>
      </div>
    </content-block>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '../components/ContentBlock';
import UIInput from '../components/ui/UIInput';
import UIButton from '../components/ui/UIButton';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    UIInput,
    UIButton,
    ValidationObserver,
  },
  props: {
    ignoreValidationForParent: {
      type: Boolean,
      default: false,
    },
  },
})
export default class PasswordChange extends Vue {
  currentPassword = '';
  password = '';
  passwordConfirmation = '';
  success = false;

  changePassword () {
    this.$refs.validationObserver.validateWithInfo().then(({ isValid, errors }) => {
      if (!isValid) return;

      this.$store.dispatch('changePassword', {
        currentPassword: this.currentPassword,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      }).then(() => {
        this.success = true;
      });
    });
  }
}
</script>

<style scoped lang="postcss">
@import "../styles";

.password-change {
  & .form {
    display: grid;
    grid-gap: var(--spacingSm);
    align-items: end;
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--desktop) {
      column-gap: var(--spacingMd);
    }
  }

  & .success {
    display: flex;
    color: var(--colorSuccess);
    fill: var(--colorSuccess);
    align-items: center;
    @apply --f6;
    margin: 0;

    & svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: var(--spacingSm);
    }
  }
}
</style>
